import Image from 'next/image'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { useUserVehicles } from '@electro/consumersite/src/hooks'
import {
  ConnectorsHumanNameType,
  retrieveSocketTypeDetails,
} from '@electro/consumersite/src/components/Map/utils'

const styles = {
  root: tw(
    'relative flex flex-col gap-y-3 pt-3 pb-4 px-3 my-3 mx-3',
    'rounded-2xl border-2 border-primary shadow-lg shadow-primary-light',
  ),
  title: tw(
    'absolute -top-4 right-2',
    'font-bold w-fit px-3',
    'rounded-full bg-base/10 backdrop-blur text-primary',
  ),
  numberPlate: {
    root: 'relative mt-2 mx-4 px-2 py-1 flex items-center bg-white rounded-lg',
    ukGreenBadge: 'absolute top-0 left-0 w-8 h-full rounded-l-lg bg-green-500',
    text: 'font-semibold text-black text-2xl 2xs:text-3xl ml-7 xs:ml-9 mb-0',
  },
  vehicleDetails: {
    root: 'bg-base-dark/60 backdrop-blur rounded-2xl py-2 px-4 text-xs [&_*]:m-0 leading-4',
    model: '[&>h4]:mb-0.5',
    battery: {
      root: 'flex flex-col gap-y-2',
      item: 'flex flex-row [&_h4]:basis-9/12 [&_h4]:-mr-1 [&_span]:font-mono',
    },
    connectors: {
      root: 'flex justify-end gap-x-2',
      type: {
        root: 'flex flex-col items-center justify-between w-10',
        icon: 'flex items-center justify-center w-10 h-10 p-2 bg-secondary/30 rounded-full',
        subtext: 'text-xs text-center truncate w-10',
      },
    },
  },
}

export const MyEVsActiveVehicle = () => {
  const { t } = useTranslation('common')
  const [{ activeVehicle }] = useUserVehicles()

  if (!activeVehicle?.vehicle) return null
  const { regNumber, vehicle } = activeVehicle

  const vehicleModelAvailableYears = {
    from: vehicle.availabilityDateFrom?.split('-')[1],
    to: vehicle.availabilityDateTo?.split('-')[1],
  }
  const vehicleModelYear =
    vehicleModelAvailableYears.from +
    (vehicleModelAvailableYears.to ? ` - ${vehicleModelAvailableYears.to}` : '+')

  const plug = retrieveSocketTypeDetails(vehicle.chargePlug as ConnectorsHumanNameType)
  const fastPlug = retrieveSocketTypeDetails(vehicle.fastchargePlug as ConnectorsHumanNameType)

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>{t('map.my_evs.active.title')}</h3>

      {regNumber ? (
        <div className={styles.numberPlate.root}>
          <span className={styles.numberPlate.ukGreenBadge} />
          <h2 className={styles.numberPlate.text}>{regNumber}</h2>
        </div>
      ) : null}

      <div className={tw(styles.vehicleDetails.root, styles.vehicleDetails.model)}>
        <h3>
          {vehicle.vehicleMake} {vehicle.vehicleModel}
        </h3>
        <h4>{vehicle.vehicleModelVersion}</h4>
        {vehicleModelAvailableYears.from ? <h4>{vehicleModelYear}</h4> : null}
      </div>

      <div className={tw(styles.vehicleDetails.root, styles.vehicleDetails.battery.root)}>
        {vehicle.batteryCapacityFull ? (
          <div className={styles.vehicleDetails.battery.item}>
            <h4>{t('map.my_evs.active.battery_size')}</h4>
            <p>
              <span>{vehicle.batteryCapacityFull.toString().split('.')[0].padStart(3, '⠀')}</span>{' '}
              kWh
            </p>
          </div>
        ) : null}
        {vehicle.rangeRealMiles ? (
          <div className={styles.vehicleDetails.battery.item}>
            <h4>{t('map.my_evs.active.real_range')}</h4>
            <p>
              <span>{vehicle.rangeRealMiles.toString().split('.')[0].padStart(3, '⠀')}</span> miles
            </p>
          </div>
        ) : null}
        {vehicle.fastchargePowerMax ? (
          <div className={styles.vehicleDetails.battery.item}>
            <h4>{t('map.my_evs.active.max_charge_speed')}</h4>
            <p>
              <span>{vehicle.fastchargePowerMax.toString().split('.')[0].padStart(3, '⠀')}</span> kW
            </p>
          </div>
        ) : null}
      </div>

      <div className={styles.vehicleDetails.root}>
        <h4>{t('map.my_evs.active.connectors')}</h4>

        <div className={styles.vehicleDetails.connectors.root}>
          {vehicle.chargePlug ? (
            <div className={styles.vehicleDetails.connectors.type.root}>
              <div className={styles.vehicleDetails.connectors.type.icon}>
                <Image src={plug.iconURL} alt={vehicle.chargePlug} width={24} height={24} />
              </div>
              <p className={styles.vehicleDetails.connectors.type.subtext}>{plug.name}</p>
            </div>
          ) : null}

          {vehicle.fastchargePlug ? (
            <div className={styles.vehicleDetails.connectors.type.root}>
              <div className={styles.vehicleDetails.connectors.type.icon}>
                <Image src={fastPlug.iconURL} alt={vehicle.fastchargePlug} width={24} height={24} />
              </div>
              <p className={styles.vehicleDetails.connectors.type.subtext}>{fastPlug.name}</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

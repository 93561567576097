import { useRouter } from 'next/router'
import React, { HTMLAttributes, useEffect, useRef } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { mapSidebarContainerStyling } from '@electro/consumersite/src/components/Map/components/MapSidebar'
import { useMapConfig, useMapSidebar } from '@electro/consumersite/src/components/Map/hooks'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { ScrollShadow, Tooltip } from '@electro/shared-ui-components'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { testEnv } from '@electro/shared/utils/isEnv'
import { useTouchDevice } from '@electro/shared/hooks'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'

const styles = {
  navMenu: tw(
    'z-20 flex flex-col items-center gap-y-6 py-1 w-12',
    'rounded-xl bg-base border-secondary border-2',
    'overflow-y-hidden',
    '[&>*]:h-full [&>*]:flex [&>*]:flex-col [&>*]:items-center [&>*]:gap-y-2',
  ),
  navIcon: {
    root: 'flex items-center justify-center w-8 h-12 hover:opacity-70',
    active: 'text-primary',
    disabledActive: 'text-secondary-light',
    hidden: 'hidden',
  },
}

export const SidebarNav = ({ children }: HTMLAttributes<HTMLDivElement>) => {
  const { asPath } = useRouter()
  const [{ showNavbar }] = useMapConfig()
  const panelName = useRef<SidebarPanels>()
  const [, { showPanelExclusive }] = useMapSidebar()

  /** Update the panel defined in the URL anchor */
  useEffect(() => {
    if (asPath.includes('#')) {
      const hash = asPath.split('#')[1].split('?')[0] as SidebarPanels
      if (Object.values(SidebarPanels).includes(hash)) panelName.current = hash
    }
  }, [asPath])

  useEffect(() => {
    /** Open the panel 2 seconds after page load, if no other panels are opened in that time */
    if (panelName.current) {
      const initialPanel = panelName.current
      setTimeout(
        () => (initialPanel === panelName.current ? showPanelExclusive(initialPanel) : null),
        testEnv ? 0 : 2000,
      )
    }
  }, [showPanelExclusive])

  return (
    <menu
      className={tw({
        [mapSidebarContainerStyling.all]: true,
        [mapSidebarContainerStyling.withPadding]: showNavbar,
        [styles.navMenu]: true,
      })}
    >
      <ScrollShadow>{children}</ScrollShadow>
    </menu>
  )
}

interface SidebarNavIconProps extends HTMLAttributes<HTMLButtonElement> {
  panelName?: SidebarPanels
}

export const SidebarNavIcon = ({ className, children, panelName }: SidebarNavIconProps) => {
  const router = useRouter()
  const [{ visiblePanels, disabledPanels, hiddenPanels }, { showPanelExclusive }] = useMapSidebar()

  const activePanel = visiblePanels[0]

  const isMap = panelName === SidebarPanels.MAP
  const isActive = activePanel === panelName

  const isDisabled = disabledPanels.includes(panelName)
  const isDisabledActive = isDisabled && activePanel === SidebarPanels.ELECTROVERSE_FEATURES

  const isHidden = hiddenPanels.includes(panelName)

  const onNavIconClick = () => {
    let targetPanel: SidebarPanels

    if (isDisabled) targetPanel = SidebarPanels.ELECTROVERSE_FEATURES
    else if (isActive) targetPanel = SidebarPanels.MAP
    else targetPanel = panelName

    showPanelExclusive(targetPanel)
    GTM.showSidebarPanel(targetPanel)

    const urlHash = router.asPath.indexOf('#')
    if (!isMap || urlHash > 0) {
      try {
        router.replace(isMap ? router.asPath.slice(0, urlHash) : `#${panelName}`)
      } catch (e) {
        // Ignores next router bug for URL hash in sentry: https://github.com/vercel/next.js/issues/37362
        if (!e.cancelled) throw e
      }
    }
  }

  return (
    <button
      onClick={onNavIconClick}
      data-testid={`sidebar-nav-icon-${panelName}`}
      className={tw({
        [styles.navIcon.root]: true,
        [styles.navIcon.active]: isActive,
        [styles.navIcon.disabledActive]: isDisabledActive,
        [styles.navIcon.hidden]: isHidden,
        [className]: !!className,
      })}
    >
      {children}
    </button>
  )
}

export const SidebarNavLabelsHelpIcon = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation('common')
  const isTouchDevice = useTouchDevice()
  const [, { showPanel, hidePanel, togglePanelVisibility }] = useMapSidebar()

  // Touch-screen devices should have show/hide clickable functionality
  const touchDeviceHandler = {
    onClick: () => togglePanelVisibility(SidebarPanels.ICON_NAV_LABELS),
  }

  // Trackpad/Mouse devices should have show/hide hover functionality
  const mouseDeviceHandler = {
    onMouseEnter: () => showPanel(SidebarPanels.ICON_NAV_LABELS),
    onMouseLeave: () => hidePanel(SidebarPanels.ICON_NAV_LABELS),
  }

  return (
    <Tooltip placement="right" disabled={isTouchDevice}>
      <Tooltip.Trigger
        delay={300}
        className="mt-auto mb-1"
        ariaLabel="Tooltip - Hover cursor to show panel names"
      >
        <QuestionMarkCircleIcon
          className={className}
          {...(isTouchDevice ? touchDeviceHandler : mouseDeviceHandler)}
        />
      </Tooltip.Trigger>
      <Tooltip.Body width={600}>{t('map.sidebar.tooltip')}</Tooltip.Body>
    </Tooltip>
  )
}

import {
  ConstantineSvg,
  FacebookSvg,
  InstaSvg,
  LinkedInSvg,
  MailSvg,
  TwitterSvg,
} from '@electro/consumersite/src/svgs'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Locales } from '@electro/shared/types/locales'
import {
  getActiveHolidaySeason,
  getSeasonalImagePath,
} from '@electro/consumersite/src/helpers/seasonalRendering'
import { OctoRegionSelect } from '@electro/consumersite/src/components/OctoRegionSelect'
import { RestrictToLocales } from '@electro/consumersite/src/components/RestrictToLocales'

const styles = {
  footer: {
    root: 'max-w-6xl w-full mx-auto px-2 relative',
    display: tw(
      'p-10 rounded-t-3xl',
      'md:grid grid-cols-2',
      'bg-constantine-silhouette-cropped bg-no-repeat',
      '!bg-[#6018C8] bg-blend-color-dodge', // Hardcoded value specific to the constantine silhouette image
    ),
    externalLinks: 'md:order-2',
    navigation: {
      root: 'md:grid md:grid-cols-2 md:order-1',
    },
    menuItem: {
      root: 'pb-4 last:pb-0',
      link: 'text-white',
    },
    social: {
      root: 'flex gap-3 xs:gap-6 mb-4 md:justify-end',
      link: ' text-white flex group mb-4 ',
      icon: 'text-white group-hover:text-primary items-center',
    },
    oe: {
      link: 'text-white flex group mb-8',
      icon: 'text-white group-hover:text-octo-primary-pink mr-2 items-center',
    },
    legal: 'text-xs text-center mt-4 md:col-span-2 md:order-3 md:text-right md:pl-64',
    pumpkins: 'absolute right-6 bottom-48 md:left-7 md:bottom-0',
  },
}

const GLOBAL_NAV_ITEMS = [
  {
    label: 'nav.footer.item.privacy',
    pathname: '/legal/privacy',
  },
  {
    label: 'nav.footer.item.terms',
    pathname: '/legal/terms',
  },
]
const LOCALE_RESTRICTED_NAV_ITEMS = [
  {
    label: 'nav.footer.item.business_terms',
    pathname: '/legal/business/terms',
  },
]

export enum SocialMediaPlatformsEnum {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedIn',
  EMAIL = 'email',
}

export interface SocialMediaLinks {
  [SocialMediaPlatformsEnum.INSTAGRAM]?: string
  [SocialMediaPlatformsEnum.TWITTER]?: string
  [SocialMediaPlatformsEnum.FACEBOOK]?: string
  [SocialMediaPlatformsEnum.LINKEDIN]?: string
  [SocialMediaPlatformsEnum.EMAIL]?: string
}

export const SocialMediaPlatformLinksMap: Record<string, SocialMediaLinks> = {
  [Locales.EN]: {
    instagram: 'https://instagram.com/octopusenergy',
    twitter: 'https://twitter.com/octopuselectro',
    facebook: 'https://www.facebook.com/OctopusElectroverse',
    linkedIn: 'https://uk.linkedin.com/company/octopus-electric-universe',
    email: 'mailto:hello@electroverse.com',
  },
  [Locales.FR]: {
    instagram: 'https://www.instagram.com/octopusenergyfr/',
    twitter: 'https://twitter.com/OctopusEnergyFr',
    email: 'mailto:bonjour@electroverse.com',
  },
  [Locales.ES]: {
    instagram: 'https://www.instagram.com/octopusenergyes/',
    twitter: 'https://twitter.com/OctopusEnergyES',
    email: 'mailto:hola@electroverse.com',
  },
  [Locales.IT]: {
    instagram: 'https://www.instagram.com/octopus_energy_italia/',
    twitter: 'https://twitter.com/OctopusEnergyIT',
    email: 'mailto:ciao@electroverse.com',
  },
  [Locales.DE]: {
    instagram: 'https://www.instagram.com/octopusenergy_de/',
    twitter: 'https://twitter.com/OctopusEnergyDE',
    facebook: 'https://www.facebook.com/octopusenergyDE',
    email: 'mailto:hallo@electroverse.com',
  },
}

export const Footer = () => {
  const { t } = useTranslation('common')
  const activeHolidaySeason = getActiveHolidaySeason()

  const { locale } = useRouter()

  const selectRegionalisedSocialMediaLink = (platform: SocialMediaPlatformsEnum) =>
    SocialMediaPlatformLinksMap[locale][platform] ??
    SocialMediaPlatformLinksMap[Locales.EN][platform]

  return (
    <footer className={styles.footer.root} data-testid="footer">
      <div className={styles.footer.display}>
        <div className={styles.footer.externalLinks} data-testid="footer-external-links">
          <div className={styles.footer.social.root} data-testid="social">
            <a
              href={selectRegionalisedSocialMediaLink(SocialMediaPlatformsEnum.EMAIL)}
              target="_blank"
              rel="noreferrer"
              className={styles.footer.social.link}
              aria-label={t('footer.link.contact_email')}
            >
              <MailSvg className={styles.footer.social.icon} />
            </a>

            <a
              href={selectRegionalisedSocialMediaLink(SocialMediaPlatformsEnum.FACEBOOK)}
              target="_blank"
              rel="noreferrer"
              className={styles.footer.social.link}
            >
              <FacebookSvg className={styles.footer.social.icon} />
            </a>

            <a
              href={selectRegionalisedSocialMediaLink(SocialMediaPlatformsEnum.TWITTER)}
              target="_blank"
              rel="noreferrer"
              className={styles.footer.social.link}
            >
              <TwitterSvg className={styles.footer.social.icon} />
            </a>

            <a
              href={selectRegionalisedSocialMediaLink(SocialMediaPlatformsEnum.LINKEDIN)}
              target="_blank"
              rel="noreferrer"
              className={styles.footer.social.link}
            >
              <LinkedInSvg className={styles.footer.social.icon} />
            </a>

            <a
              href={selectRegionalisedSocialMediaLink(SocialMediaPlatformsEnum.INSTAGRAM)}
              target="_blank"
              rel="noreferrer"
              className={styles.footer.social.link}
            >
              <InstaSvg className={styles.footer.social.icon} />
            </a>
          </div>
          <div data-testid="oe-link" className="md:flex md:justify-end group">
            <OctoRegionSelect>
              <div className="flex -ml-4 mb-4 group-hover:text-octo-primary-pink">
                <ConstantineSvg className={styles.footer.oe.icon} />
                Octopus Energy
              </div>
            </OctoRegionSelect>
          </div>
        </div>

        <nav data-testid="footer-navigation" className={styles.footer.navigation.root}>
          <ul>
            {GLOBAL_NAV_ITEMS.map((item) => (
              <li key={item.label} className={styles.footer.menuItem.root}>
                <Link legacyBehavior href="/[...slug]" as={item.pathname}>
                  <a className={styles.footer.menuItem.link}>{t(item.label)}</a>
                </Link>
              </li>
            ))}

            <RestrictToLocales locales={[Locales.EN]}>
              {LOCALE_RESTRICTED_NAV_ITEMS.map((item) => (
                <li key={item.label} className={styles.footer.menuItem.root}>
                  <Link legacyBehavior href="/[...slug]" as={item.pathname}>
                    <a className={styles.footer.menuItem.link}>{t(item.label)}</a>
                  </Link>
                </li>
              ))}
            </RestrictToLocales>
          </ul>
        </nav>
        <div className={styles.footer.legal} data-testid="legal">
          <p>
            <span>
              Octopus Electroverse Ltd is a company registered in England and Wales. Registered
              office: 15274675. Registered office: 5th Floor, UK House, 164-182 Oxford Street,
              London, W1D 1NN.
            </span>{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://octopus.energy/policies/modern-slavery-statement/"
            >
              Modern slavery statement
            </a>
            .
          </p>
        </div>
      </div>
      {activeHolidaySeason ? (
        <Image
          src={getSeasonalImagePath('collection-of-items', activeHolidaySeason)}
          width={174}
          height={107}
          alt="Collection of seasonal items!"
          className={styles.footer.pumpkins}
        />
      ) : null}
    </footer>
  )
}

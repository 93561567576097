import { Locales, Locale } from '@electro/shared/types/locales'
import { mapLocaleToCountryDetails } from '@electro/shared/utils/mapLocaleToCountryDetails'
import { FlagIcon, FlagIconCode } from 'react-flag-kit'
import { Menu } from '@electro/shared-ui-components'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

const styles = {
  root: '-mr-4 font-mono',
  switcher: {
    button: 'md:[&_svg]:ml-2 lg:[&_svg]:ml-4 lg:pr-4 lg:pl-0 lg:py-4',
    active: 'text-white',
    list: 'mt-3 ml-3 lg:ml-0',
    label: 'flex font-normal',
    flag: 'mr-1 my-auto rounded-sm shadow-lg',
  },
}

const locales = [Locales.EN, Locales.FR, Locales.ES, Locales.IT, Locales.DE]

export const LanguageSelect = () => {
  const { locale, asPath } = useRouter()
  const getLocaleAlpha2Code = useCallback(
    (loc: Locale): string => mapLocaleToCountryDetails(loc).alpha2 || '',
    [],
  )

  /**
   * Not all regions have equivalent pages! To work around this
   * when a user switches language. The only page they will stay
   * on is the map page. If they are on any other page they will be
   * sent to the homepage. This is to prevent users from seeing bilingual
   * page content or 404 errors.
   */
  const handleSwitchLocale = useCallback(
    (loc: Locale) => () => {
      const nextPath = `/${loc}${asPath.includes('/map') ? asPath : '/'}`
      const sanitizedDefaultPaths = nextPath.replace(`${Locales.EN}/`, '')
      window.location.href = `${window.location.origin}${sanitizedDefaultPaths}`
    },
    [asPath],
  )

  return (
    <div className={styles.root} data-testid="language-select-menu">
      <Menu>
        <Menu.Button
          className={styles.switcher.button}
          activeClassName={styles.switcher.active}
          chevronActiveClassName={styles.switcher.active}
        >
          <div className={styles.switcher.label}>
            <FlagIcon
              code={getLocaleAlpha2Code(locale as Locale) as FlagIconCode}
              className={styles.switcher.flag}
              size={20}
            />
            <span>{getLocaleAlpha2Code(locale as Locale)}</span>
          </div>
        </Menu.Button>
        <Menu.MenuItemList className={styles.switcher.list}>
          {locales.map((loc) =>
            loc !== locale ? (
              <Menu.MenuItem key={loc} onSelect={handleSwitchLocale(loc)}>
                <FlagIcon
                  code={getLocaleAlpha2Code(loc) as FlagIconCode}
                  className={styles.switcher.flag}
                  size={20}
                />
                <span data-testid="locale-menu-item">{getLocaleAlpha2Code(loc)}</span>
              </Menu.MenuItem>
            ) : null,
          )}
        </Menu.MenuItemList>
      </Menu>
    </div>
  )
}

/* eslint-disable no-underscore-dangle */
import {
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
  ReactNode,
  useRef,
  useEffect,
} from 'react'
import { useMap } from 'react-map-gl'
import { useMapLocationID } from '@electro/consumersite/src/components/Map/hooks'
import { ChargingLocationFragment } from '@electro/consumersite/generated/graphql'
import {
  EjnMarker,
  ElasticLocation,
  SearchMarker,
} from '@electro/consumersite/src/components/Map/types'

interface State {
  activeLocationMarker: EjnMarker
  locationDetailsOpen: boolean
  searchMarker: SearchMarker
}

interface Handlers {
  clearSearchMarker: () => void
  clearActiveMarker: () => void
  setSearchMarker: (marker: SearchMarker) => void
  setActiveLocationMarker: (marker: EjnMarker) => void
  toggleLocationDetailsPanel: ({ open }: { open: boolean }) => void
  handleSelectLocation: (location: ElasticLocation) => Promise<ChargingLocationFragment>
}

type UseMarkers = [state: State, handlers: Handlers]

const UseMarkersContext = createContext<UseMarkers>(null)

function useMarkersProvider(): UseMarkers {
  const { baseMap } = useMap()
  const [, { updateLocationID }] = useMapLocationID()

  const [searchMarker, setSearchMarker] = useState<SearchMarker>(null)
  const [locationDetailsOpen, setDetailsOpen] = useState<boolean>(false)
  const [activeLocationMarker, setActiveLocationMarker] = useState<EjnMarker>(null)

  const clearSearchMarker = useCallback(() => setSearchMarker(null), [])
  const clearActiveMarker = useCallback(() => setActiveLocationMarker(null), [])

  const toggleLocationDetailsPanel = useCallback(({ open }: { open?: boolean } = {}) => {
    setDetailsOpen((oldValue) => {
      if (open === false) return open
      if (open) return open
      return !oldValue
    })
  }, [])

  /**
   * @handleSelectLocation
   * Elastic search returns different lat lng values at various zoom levels!
   * This was causing an issue where a marker clicked on at a high zoom level would
   * have a mismatch in coordinates when zoomed in.
   * In order to ensure our users see the correct location at all zoom levels
   * the active marker pin is set on click using the location coords returned by elastic search,
   * then it updates once we have coordinates data from the `chargingLocation` endpoint
   */
  const handleSelectLocation = useCallback(
    async (location: ElasticLocation) => {
      try {
        toggleLocationDetailsPanel({ open: true })
        if (location?.geometry?.coordinates.length > 0) {
          setActiveLocationMarker({
            id: location.properties._id,
            icon: location.properties.is_ejn_location ? 'active-ejn' : 'active',
            coordinates: location.geometry.coordinates,
            isEjnLocation: location.properties.is_ejn_location,
          })
        }

        const locationData = await updateLocationID(location?.properties._id)
        setActiveLocationMarker({
          id: location?.properties._id,
          icon: locationData?.isEjnLocation ? 'active-ejn' : 'active',
          coordinates: [locationData?.coordinates.latitude, locationData?.coordinates.longitude],
          isEjnLocation: locationData?.isEjnLocation,
        })
        return locationData
      } catch {
        // Error handling is done in the updateLocationID callback
        toggleLocationDetailsPanel({ open: false })
      }

      return null
    },
    [updateLocationID, toggleLocationDetailsPanel],
  )

  /**
   * When the baseMap is present we want to bind some event listeners to our markers.
   *
   * NOTE: In order to prevent multiple event listeners from firing we use a ref to
   * track if the listener has been bound to a given set of layers. This is
   * important because the handleSelectLocation function is called multiple times
   * if the user clicks on multiple markers eventually resulting in performance issues
   * and browser errors.
   */
  const hasMarkerListenerBeenBound = useRef(false)
  useEffect(() => {
    const selectMarkerListener = () => {
      if (baseMap && !hasMarkerListenerBeenBound.current) {
        baseMap?.on('click', 'charging_locations_non_ejn_circles', (e) => {
          const [location] = e.features as ElasticLocation[]
          handleSelectLocation(location)
        })
        baseMap?.on('click', 'charging_locations_ejn_symbols', (e) => {
          const [location] = e.features as ElasticLocation[]
          handleSelectLocation(location)
        })
        baseMap?.on('click', 'charging_locations_all', (e) => {
          const [location] = e.features as ElasticLocation[]
          handleSelectLocation(location)
        })
        hasMarkerListenerBeenBound.current = true
      }
    }
    selectMarkerListener()
  }, [baseMap, handleSelectLocation])

  const state = useMemo(
    () => ({ activeLocationMarker, locationDetailsOpen, searchMarker }),
    [activeLocationMarker, locationDetailsOpen, searchMarker],
  )

  const handlers = useMemo(
    () => ({
      clearSearchMarker,
      clearActiveMarker,
      setSearchMarker,
      setActiveLocationMarker,
      toggleLocationDetailsPanel,
      handleSelectLocation,
    }),
    [
      clearSearchMarker,
      clearActiveMarker,
      setSearchMarker,
      setActiveLocationMarker,
      toggleLocationDetailsPanel,
      handleSelectLocation,
    ],
  )

  return [state, handlers]
}

export const UseMarkersProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const ctx = useMarkersProvider()
  return <UseMarkersContext.Provider value={ctx}>{children}</UseMarkersContext.Provider>
}

export const useMarkers = (): UseMarkers => {
  const context = useContext(UseMarkersContext)
  if (!context) throw new Error('useMarkers() cannot be used outside of <UseMarkersProvider/>')
  return context
}

import NextHead from 'next/head'
import { extend } from '@electro/shared/theme/electro'
import { devOrPreviewEnv } from '@electro/shared/utils/isEnv'

const favicons = devOrPreviewEnv
  ? { icon: '/favicon/favicon-dev.ico', svg: '/favicon/icon-dev.svg' }
  : { icon: '/favicon.ico', svg: '/favicon/icon.svg' }

interface HeadProps extends Partial<HTMLHeadElement> {
  children?: any
  description?: string
  image?: string
  showAppBanner?: boolean
}

export const Head = ({
  children,
  title = 'Electroverse',
  description,
  image = 'https://electroverse.octopus.energy/images/og-image-default.png',
  showAppBanner = true,
}: HeadProps) => (
  <NextHead>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="theme-color" content={extend.colors.base.DEFAULT} />

    {/* https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs */}
    <link rel="icon" href={favicons.icon} sizes="32x32" />
    <link rel="icon" href={favicons.svg} type="image/svg+xml" />
    <link rel="apple-touch-icon" href="/favicon/apple-touch-icon.png" />
    <link rel="manifest" href="/favicon/manifest.webmanifest" />

    <meta name="viewport" content="width=device-width, minimal-ui" />
    <meta name="mobile-web-app-capable" content="yes" />
    {showAppBanner && <meta name="apple-itunes-app" content="app-id=1535444463" />}

    <meta property="og:image" content={image} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:site" content="@OctopusElectro" />
    <meta name="twitter:card" content="summary_large_image" />

    {image.includes('/images/og-image-') && (
      <>
        {/* Most social media sites recommend roughly these dimensions */}
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="600" />

        <meta name="twitter:image:type" content="image/png" />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="600" />
      </>
    )}

    {children}
  </NextHead>
)

import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useUpdateEffect } from 'react-use'
import useTranslation from 'next-translate/useTranslation'
import { useToastNotification } from '@electro/shared-ui-components'
import { useMapDrawer } from '@electro/consumersite/src/components/Map/hooks'
import { DrawerPanels } from '@electro/consumersite/src/components/Map/types'
import {
  ChargingLocationFragment,
  ChargingLocationMetadataType,
  useChargingLocationFragmentQuery,
  useChargingLocationQuery,
} from '@electro/consumersite/generated/graphql'

interface State {
  locationID: string
  locationFragment: ChargingLocationFragment
  locationLoading: boolean
  locationData: ChargingLocationMetadataType
}

interface Handlers {
  updateLocationID: (pk: string) => Promise<ChargingLocationFragment>
  clearLocationID: () => void
}

type UseMapLocationID = [state: State, handlers: Handlers]
const UseMapLocationIDContext = createContext<UseMapLocationID>(null)

function useMapLocationIDProvider(): UseMapLocationID {
  const router = useRouter()
  const { t } = useTranslation('common')
  const [, { updateVisibleDrawer }] = useMapDrawer()
  const { showToastNotification } = useToastNotification()

  const { refetch: fetchLocationFragment } = useChargingLocationFragmentQuery({ skip: true })
  const { refetch: fetchLocationData } = useChargingLocationQuery({
    skip: true,
    fetchPolicy: 'cache-and-network',
  })

  const [locationID, setLocationID] = useState<string>()
  const [locationFragment, setLocationFragment] = useState<ChargingLocationFragment>()
  const [locationLoading, setLocationLoading] = useState<boolean>(false)
  const [locationData, setLocationData] = useState<ChargingLocationMetadataType>()

  /** Adds chargingLocationPk to the URL to enable location sharing and mobile deep linking */
  const addLocationIDToURL = useCallback(
    (locationId: string) => {
      if (router.asPath) {
        const splitURL = window.location.pathname.split('/')
        const locationIndex = splitURL.indexOf('location')

        if (locationId) {
          if (locationIndex > 0) splitURL.splice(locationIndex + 1, 1, locationId)
          else splitURL.push('location', locationId)

          const urlPath = splitURL.join('/')
          const urlQueryParams = window.location.search

          router.replace(`${urlPath}${urlQueryParams}`, undefined, { shallow: true })
        }
      }
    },
    [router],
  )

  /** Removes /location/123456 from the URL if it exists */
  const removeLocationIDFromURL = useCallback(() => {
    if (router.asPath) {
      const splitURL = window.location.pathname.split('/')
      const locationIndex = splitURL.indexOf('location')

      if (locationIndex > 0) {
        splitURL.splice(locationIndex, 2)

        const urlPath = splitURL.join('/')
        const urlQueryParams = window.location.search

        router.replace(`${urlPath}${urlQueryParams}`, undefined, { shallow: true })
      }
    }
  }, [router])

  /** Handles multiple interactions with the charging location query.
   * Including fetching a subset of data, fetching the full data,
   * adding the location ID to the URL and query error handling.
   *
   * Returns the subset of charger data. */
  const updateLocationID = useCallback(
    async (pk: string) => {
      try {
        const { data: fragmentData } = await fetchLocationFragment({ pk })

        if (fragmentData?.chargingLocation) {
          setLocationFragment(fragmentData.chargingLocation)
          addLocationIDToURL(pk)
          setLocationID(pk)

          setLocationLoading(true)
          fetchLocationData({ pk }).then(({ data: fullData }) => {
            setLocationLoading(false)
            if (fullData?.chargingLocation) {
              setLocationData(fullData.chargingLocation as ChargingLocationMetadataType)
            }
          })

          return fragmentData.chargingLocation as ChargingLocationFragment
        }
      } catch (error) {
        showToastNotification({ variant: 'error', heading: t('common.error'), body: error.message })
      }

      setLocationLoading(false)
      return undefined
    },
    [fetchLocationFragment, fetchLocationData, addLocationIDToURL, showToastNotification, t],
  )

  /** Reset the context hook to default state */
  const clearLocationID = useCallback(() => {
    setLocationID(undefined)
    setLocationFragment(undefined)
    setLocationLoading(false)
    setLocationData(undefined)
    removeLocationIDFromURL()
  }, [removeLocationIDFromURL])

  /** Show the location drawer if locationID is defined */
  useUpdateEffect(() => {
    updateVisibleDrawer(locationID ? DrawerPanels.LOCATION_DETAILS : undefined)
  }, [locationID, updateVisibleDrawer])

  const state = useMemo(
    () => ({ locationID, locationFragment, locationLoading, locationData }),
    [locationID, locationFragment, locationLoading, locationData],
  )

  const handlers = useMemo(
    () => ({ updateLocationID, clearLocationID }),
    [updateLocationID, clearLocationID],
  )

  return [state, handlers]
}

export const UseMapLocationIDProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const ctx = useMapLocationIDProvider()
  return <UseMapLocationIDContext.Provider value={ctx}>{children}</UseMapLocationIDContext.Provider>
}

export const useMapLocationID = (): UseMapLocationID => {
  const context = useContext(UseMapLocationIDContext)
  if (!context)
    throw new Error('useMapLocationID() cannot be used outside of <UseMapLocationIDProvider/>')
  return context
}

import Trans from 'next-translate/Trans'

export enum ConnectorsURLNameEnum {
  CCS = 'CCS',
  CHADEMO = 'CHADEMO',
  TYPE_1 = 'TYPE_1',
  TYPE_2 = 'TYPE_2',
  OTHER = 'OTHER',
}

export enum ConnectorsHumanNameEnum {
  CCS = 'CCS',
  CHADEMO = 'CHAdeMO',
  TYPE_1 = 'Type 1',
  TYPE_1_CCS = 'Type 1 ccs',
  TYPE_2 = 'Type 2',
  TYPE_3 = 'Type 3',
  TYPE_3A = 'Type 3A',
  TYPE_3C = 'Type_3C',
  TWO_PIN = '2 pin',
  THREE_PIN = '3 pin',
  TESLA_CONNECTOR = 'Tesla Connector',
  TESLA_ROADSTER_CONNECTOR = 'Tesla Roadster Connector',
  INDUSTRIAL_CONNECTOR = 'Industrial Connector',
  DOMESTIC = 'Domestic',
  SCHUKO = 'Schuko',
  OTHER = 'Other',
}

export enum ConnectorsMacroCaseEnum {
  CCS = 'CCS',
  ChaDeMo = 'CHADEMO',
  'Type 1' = 'TYPE_1',
  'Type 1 ccs' = 'TYPE_1_CCS',
  'Type 2' = 'TYPE_2',
  'Type 3' = 'TYPE_3',
  'Type 3A' = 'TYPE_3A',
  'Type 3C' = 'TYPE_3C',
  '2 pin' = 'TWO_PIN',
  '3 pin' = 'THREE_PIN',
  'Tesla Connector' = 'TESLA_CONNECTOR',
  'Tesla Roadster Connector' = 'TESLA_ROADSTER_CONNECTOR',
  'Industrial Connector' = 'INDUSTRIAL_CONNECTOR',
  Domestic = 'DOMESTIC',
  Schuko = 'SCHUKO',
  Other = 'OTHER',
}

export type ConnectorsURLNameType = `${ConnectorsURLNameEnum}`
export type ConnectorsHumanNameType = `${ConnectorsHumanNameEnum}`

const connectorsIconMap: Record<
  ConnectorsHumanNameEnum,
  { name: string | JSX.Element; iconURL: string }
> = {
  [ConnectorsHumanNameEnum.CCS]: {
    name: 'CCS',
    iconURL: '/images/connectors/CCS.svg',
  },
  [ConnectorsHumanNameEnum.CHADEMO]: {
    name: 'CHAdeMO',
    iconURL: '/images/connectors/CHAdeMO.svg',
  },
  [ConnectorsHumanNameEnum.TYPE_1]: {
    name: <Trans i18nKey="common:map.filters.connectors.type" values={{ connectorID: 1 }} />,
    iconURL: '/images/connectors/Type_1.svg',
  },
  [ConnectorsHumanNameEnum.TYPE_1_CCS]: {
    name: <Trans i18nKey="common:map.filters.connectors.type" values={{ connectorID: '1 CCS' }} />,
    iconURL: '/images/connectors/Type_3.svg',
  },
  [ConnectorsHumanNameEnum.TYPE_2]: {
    name: <Trans i18nKey="common:map.filters.connectors.type" values={{ connectorID: 2 }} />,
    iconURL: '/images/connectors/Type_2.svg',
  },
  [ConnectorsHumanNameEnum.TYPE_3]: {
    name: <Trans i18nKey="common:map.filters.connectors.type" values={{ connectorID: 3 }} />,
    iconURL: '/images/connectors/Type_3.svg',
  },
  [ConnectorsHumanNameEnum.TYPE_3A]: {
    name: <Trans i18nKey="common:map.filters.connectors.type" values={{ connectorID: '3A' }} />,
    iconURL: '/images/connectors/Type_3.svg',
  },
  [ConnectorsHumanNameEnum.TYPE_3C]: {
    name: <Trans i18nKey="common:map.filters.connectors.type" values={{ connectorID: '3C' }} />,
    iconURL: '/images/connectors/Type_3C.svg',
  },
  [ConnectorsHumanNameEnum.TWO_PIN]: {
    name: <Trans i18nKey="common:map.filters.connectors.pin" values={{ connectorID: 2 }} />,
    iconURL: '/images/connectors/2_Pin.svg',
  },
  [ConnectorsHumanNameEnum.THREE_PIN]: {
    name: <Trans i18nKey="common:map.filters.connectors.pin" values={{ connectorID: 3 }} />,
    iconURL: '/images/connectors/3_Pin.svg',
  },
  [ConnectorsHumanNameEnum.TESLA_CONNECTOR]: {
    name: <Trans i18nKey="common:map.filters.connectors.tesla_connector" />,
    iconURL: '/images/connectors/Type_2.svg',
  },
  [ConnectorsHumanNameEnum.TESLA_ROADSTER_CONNECTOR]: {
    name: <Trans i18nKey="common:map.filters.connectors.tesla_roadster_connector" />,
    iconURL: '/images/connectors/Other.svg',
  },
  [ConnectorsHumanNameEnum.INDUSTRIAL_CONNECTOR]: {
    name: <Trans i18nKey="common:map.filters.connectors.industrial_connector" />,
    iconURL: '/images/connectors/Other.svg',
  },
  [ConnectorsHumanNameEnum.DOMESTIC]: {
    name: <Trans i18nKey="common:map.filters.connectors.domestic" />,
    iconURL: '/images/connectors/Other.svg',
  },
  [ConnectorsHumanNameEnum.SCHUKO]: {
    name: 'Schuko',
    iconURL: '/images/connectors/Schuko.svg',
  },
  [ConnectorsHumanNameEnum.OTHER]: {
    name: <Trans i18nKey="common:map.filters.connectors.other" />,
    iconURL: '/images/connectors/Other.svg',
  },
}

/** Match each recognised socket type to a localised name and icon */
export const retrieveSocketTypeDetails = (connectorName: ConnectorsHumanNameType) =>
  connectorsIconMap[connectorName] || {
    name: <Trans i18nKey="common:map.filters.connectors.unknown" />,
    iconURL: '/images/connectors/Other.svg',
  }

export const ElectroverseMobileDeviceSvg = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4C4 3.20435 4.31607 2.44129 4.87868 1.87868C5.44129 1.31607 6.20435 1 7 1H13C13.7956 1 14.5587 1.31607 15.1213 1.87868C15.6839 2.44129 16 3.20435 16 4V16C16 16.7956 15.6839 17.5587 15.1213 18.1213C14.5587 18.6839 13.7956 19 13 19H7C6.20435 19 5.44129 18.6839 4.87868 18.1213C4.31607 17.5587 4 16.7956 4 16V4ZM8 2.5V3.25C8 3.664 8.336 4 8.75 4H11.25C11.4489 4 11.6397 3.92098 11.7803 3.78033C11.921 3.63968 12 3.44891 12 3.25V2.5H13C13.3978 2.5 13.7794 2.65804 14.0607 2.93934C14.342 3.22064 14.5 3.60218 14.5 4V16C14.5 16.3978 14.342 16.7794 14.0607 17.0607C13.7794 17.342 13.3978 17.5 13 17.5H7C6.60218 17.5 6.22064 17.342 5.93934 17.0607C5.65804 16.7794 5.5 16.3978 5.5 16V4C5.5 3.60218 5.65804 3.22064 5.93934 2.93934C6.22064 2.65804 6.60218 2.5 7 2.5H8Z"
      fill="white"
      className="fill-current"
    />
    <path
      d="M8.01457 10.7029L9.82833 5.19511C9.92922 4.88877 10.3818 4.96179 10.3812 5.28431L10.3747 8.82217C10.3744 8.99902 10.5343 9.133 10.7084 9.10175L12.0059 8.86883C12.2084 8.83247 12.381 9.01715 12.331 9.21675L10.6867 15.785C10.6031 16.119 10.1085 16.0433 10.1286 15.6996L10.4006 11.0551C10.4113 10.872 10.248 10.727 10.0675 10.7594L8.33397 11.0706C8.12258 11.1086 7.94739 10.9069 8.01457 10.7029Z"
      fill="white"
      className="fill-current"
    />
  </svg>
)

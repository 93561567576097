import { useRouter } from 'next/router'
import { Transition } from '@headlessui/react'
import { BoltIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { LinkIcon } from '@heroicons/react/24/outline'
import { tw } from '@electro/shared/utils/tailwind-merge'

import {
  IconButton,
  Card,
  LoadingBolt,
  Typography,
  useToastNotification,
  Tag,
} from '@electro/shared-ui-components'
import { ChargingLocationMetadataType } from '@electro/consumersite/generated/graphql'
import {
  ConnectorsList,
  Summary,
  OperatorLogo,
  LocationInformation,
  LocationDetailsTabs,
  LocationAlerts,
  LocationImageGallery,
  LocationDebugInfo,
} from '@electro/consumersite/src/components/Map/components/MapLocationDetailsPanel/components'
import { useEffect, useMemo, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { RestrictToLocales } from '@electro/consumersite/src/components'

import { Locales } from '@electro/shared/types/locales'
import { useFetchLocationPhotos } from '@electro/consumersite/src/services'
import {
  useMapConfig,
  useMarkers,
  useMapLocationID,
} from '@electro/consumersite/src/components/Map/hooks'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import { formatLocationDetailsAnalytics } from '@electro/consumersite/src/components/Map/components/MapLocationDetailsPanel/helpers'

interface MapLocationDetailsPanelProps {
  loading: boolean
  chargingLocation: ChargingLocationMetadataType
  onClose: () => void
  open: boolean
}

const styles = {
  root: tw(
    'transition absolute z-40 top-16 w-screen h-[calc(100vh-5rem)] pointer-events-none',
    'sm:max-w-sm sm:w-112 sm:right-2',
    'sm:top-32',
  ),
  ejnRoot: 'bottom-0',
  panel: tw(
    'z-30 h-full pointer-events-auto overflow-auto',
    'relative top-0 bottom-0 pb-32 pt-10 px-6',
    'sm:h-auto',
    'sm:pb-6 sm:top-0 sm:left-auto sm:relative ',
  ),
  nonEjnPanel: 'sm:max-h-[calc(100vh-theme(spacing.40))] sm:mt-4',
  ejnPanel: 'sm:max-h-[calc(100vh-theme(spacing.44))]',
  iconButtons: 'flex justify-end -mt-6 -mr-2',
  loadingAnimation: 'my-16',
  loadingText: 'text-primary text-center mt-2',
  ejnLabel: {
    root: 'text-white relative z-20 -bottom-5 -mt-5 h-11 pt-1 pb-6 w-full overflow-hidden',
    boltIcon: 'w-3 h-3 text-white mr-1 -ml-2',
    text: 'flex justify-center items-center text-xs font-medium tracking-wide relative z-10',
    gradientBackground:
      'ej-gradient-full sm:opacity-80 absolute top-0 left-0 w-full h-full rounded-t-2xl',
  },
  alert: 'mt-2 mb-10 pt-2 pb-2 text-xs',
  alertWarningText: 'relative -bottom-0.5',
}

const MapLocationDetailsPanel = ({
  loading,
  chargingLocation,
  open,
  onClose,
}: MapLocationDetailsPanelProps) => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')
  const [elapsedTimeStart, setElapsedTimeStart] = useState<number>()

  const [{ allowUserLogin }] = useMapConfig()
  const { showToastNotification } = useToastNotification()
  const [fetchPhotos, { loading: photosLoading, data: photosData, error: photosError }] =
    useFetchLocationPhotos({
      fetchPolicy: 'cache-first',
    })

  useEffect(() => {
    if (chargingLocation?.chargingLocationPk) {
      setElapsedTimeStart(new Date().getTime())
      fetchPhotos({ variables: { chargingLocation: chargingLocation?.chargingLocationPk } })
      GTM.openMapLocationDetailsPanel(formatLocationDetailsAnalytics({ chargingLocation, locale }))
    }
  }, [chargingLocation, setElapsedTimeStart, fetchPhotos, locale])

  const showLoadingScreen = useMemo(() => loading || photosLoading, [loading, photosLoading])

  const onCloseCharger = () => {
    const elapsedTime = Math.floor(new Date().getTime() - elapsedTimeStart)
    const hasPhotos = photosData?.locationPhotos?.length > 0
    const chargerData = formatLocationDetailsAnalytics({ chargingLocation, locale })
    GTM.closeMapLocationDetailsPanel({ ...chargerData, elapsedTime, hasPhotos })
    onClose()
  }

  return (
    <Transition
      id="root"
      className={styles.root}
      show={open}
      enter="transition ease-in-out duration-300"
      enterFrom="translate-y-full sm:translate-x-full sm:translate-y-0"
      enterTo="translate-y-0  sm:translate-x-0 sm:translate-y-0"
      leave="transition ease-in-out duration-300"
      leaveFrom="translate-y-0 sm:translate-x-0 sm:translate-y-0"
      leaveTo="translate-y-full sm:translate-x-full sm:translate-y-0"
    >
      {chargingLocation?.isEjnLocation && (
        <div className={styles.ejnLabel.root} id="ej-label">
          <div className={styles.ejnLabel.text}>
            <BoltIcon className={styles.ejnLabel.boltIcon} />
            {t('map.location.works_with_oeu_label')}
          </div>
          <div className={styles.ejnLabel.gradientBackground} />
        </div>
      )}
      <Card
        frostedDark
        id="panel"
        data-testid="map-location-details-panel"
        data-locid={chargingLocation?.pk}
        className={tw({
          [styles.panel]: true,
          [styles.nonEjnPanel]: !chargingLocation?.isEjnLocation,
          [styles.ejnPanel]: chargingLocation?.isEjnLocation,
        })}
      >
        <div className={styles.iconButtons}>
          <IconButton
            aria-label="Share charger location"
            onClick={() => {
              navigator.clipboard.writeText(window.location.href)
              showToastNotification({
                variant: 'default',
                heading: t('map.location.share_charger.heading'),
                body: t('map.location.share_charger.body'),
                position: 'bottomLeft',
                timeout: 4000,
              })
              GTM.shareCharger({
                locationID: parseInt(chargingLocation?.chargingLocationPk, 10),
                operator: chargingLocation?.operator?.name,
              })
            }}
          >
            <LinkIcon />
          </IconButton>
          <IconButton aria-label="Close location details" onClick={onCloseCharger}>
            <XMarkIcon />
          </IconButton>
        </div>
        {showLoadingScreen ? (
          <LoadingBolt
            subtitle={t('utility.loading_message')}
            className={styles.loadingAnimation}
          />
        ) : (
          chargingLocation && (
            <div>
              {!(
                chargingLocation?.isEjnLocation ||
                // TODO: hasPartneredLocations is a temporary fix for EcoMovement locations being incorrectly reported as 'Not Partnered'
                chargingLocation?.operator?.hasPartneredLocations
              ) && (
                <Tag
                  colour="orange"
                  variant="outlined"
                  className={styles.alert}
                  label={t('map.location.not_partnered')}
                />
              )}
              <OperatorLogo chargingLocation={chargingLocation} />
              <LocationDebugInfo chargingLocation={chargingLocation} />
              <div className="mt-4 mb-4" data-testid="location-details-address">
                <Typography variant="h4">{`${chargingLocation?.address}, ${chargingLocation?.postalCode}`}</Typography>
              </div>
              {allowUserLogin && photosData?.locationPhotos?.length > 0 ? (
                <LocationImageGallery
                  chargingLocation={chargingLocation}
                  error={photosError}
                  data={photosData}
                />
              ) : null}
              <Summary chargingLocation={chargingLocation} />

              <RestrictToLocales locales={[Locales.EN]}>
                <LocationAlerts alerts={chargingLocation?.alerts} />
              </RestrictToLocales>

              <LocationDetailsTabs>
                {({ tabIndex }) => (
                  <>
                    {tabIndex === 0 && <ConnectorsList evses={chargingLocation?.evses.edges} />}
                    {tabIndex === 1 && <LocationInformation chargingLocation={chargingLocation} />}
                  </>
                )}
              </LocationDetailsTabs>
            </div>
          )
        )}
      </Card>
    </Transition>
  )
}

const LocationDetailsPanelContainer = () => {
  const [{ locationData, locationLoading }, { clearLocationID }] = useMapLocationID()

  const [{ locationDetailsOpen }, { clearActiveMarker, toggleLocationDetailsPanel }] = useMarkers()

  function handleLocationDetailPanelClose() {
    toggleLocationDetailsPanel({ open: false })
    clearActiveMarker()

    clearLocationID()
  }

  return (
    <MapLocationDetailsPanel
      loading={locationLoading}
      chargingLocation={locationData}
      open={locationDetailsOpen}
      onClose={handleLocationDetailPanelClose}
    />
  )
}

export { LocationDetailsPanelContainer as LocationDetailsPanel, MapLocationDetailsPanel }

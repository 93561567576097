import { fireGtmEvent } from '@electro/shared/utils/fireGtmEvent'
import { RouteTitles } from '@electro/consumersite/src/components/Map/helpers/getRouteSummaryTitles'
import { LocationDetailsType } from '@electro/consumersite/src/components/Map/components/MapLocationDetailsPanel/helpers'
import {
  MapSearchResultTypes,
  UseMapSearchProps,
} from '@electro/consumersite/src/components/Map/hooks'
import {
  MapFiltersType,
  RouteFormFields,
  SidebarPanels,
} from '@electro/consumersite/src/components/Map/types'

enum GTM_EVENTS {
  MAP_SEARCH_SUBMIT = 'WEB::MAP_SEARCH_SUBMIT',
  SHOW_SIDEBAR_PANEL = 'WEB::SHOW_SIDEBAR_PANEL',
  MAP_FILTERS_SUBMIT = 'WEB::MAP_FILTERS_SUBMIT',
  ROUTE_PLANNER_OPEN = 'WEB::ROUTE_PLANNER_OPEN',
  ROUTE_PLANNER_SUBMIT = 'WEB::ROUTE_PLANNER_SUBMIT',
  ROUTE_PLANNER_OPEN_ROUTE_DETAILS = 'WEB::ROUTE_PLANNER_OPEN_ROUTE_DETAILS',
  MAP_LOCATION_DETAILS_OPEN = 'WEB::MAP_LOCATION_DETAILS_OPEN',
  MAP_LOCATION_DETAILS_CLOSE = 'WEB::MAP_LOCATION_DETAILS_CLOSE',
  SHARE_CHARGER = 'WEB::SHARE_CHARGER',
  FEEDBACK_MOOD_LEVEL = 'WEB::FEEDBACK_MOOD_LEVEL',
  TOGGLE_QUICK_FILTER = 'WEB::TOGGLE_QUICK_FILTER',
  SHARE_URL = 'WEB::SHARE_URL',
}

// prettier-ignore
type SubmitMapSearchType = { target: UseMapSearchProps['target']; searchType: MapSearchResultTypes }
export const submitMapSearch = (mapSearchDetails: SubmitMapSearchType) =>
  fireGtmEvent({
    event: GTM_EVENTS.MAP_SEARCH_SUBMIT,
    ...mapSearchDetails,
  })

export const showSidebarPanel = (panelName: SidebarPanels) =>
  fireGtmEvent({
    event: GTM_EVENTS.SHOW_SIDEBAR_PANEL,
    panelName,
  })

// prettier-ignore
type SubmitFiltersType = { currentFilters: Omit<MapFiltersType, 'operators'> & { operators: string[] } }
export const submitFilters = ({ currentFilters }: SubmitFiltersType) =>
  fireGtmEvent({
    event: GTM_EVENTS.MAP_FILTERS_SUBMIT,
    ...currentFilters,
  })

export const openRoutePlanner = () =>
  fireGtmEvent({
    event: GTM_EVENTS.ROUTE_PLANNER_OPEN,
  })

type SubmitRouteType = RouteFormFields & { isInternationalRoute: boolean; waypointCounter: number }
export const submitRoutePlanner = (submitRouteFields: SubmitRouteType) =>
  fireGtmEvent({
    event: GTM_EVENTS.ROUTE_PLANNER_SUBMIT,
    ...submitRouteFields,
  })

type DurationType = { totalSeconds: number; hours: number; minutes: number }
// prettier-ignore
type RouteDetailsType = { summaryTitle?: RouteTitles; distanceMetres: number; duration: {journey: DurationType; driving: DurationType; charge: DurationType} }
export const openRouteDetails = (routeDetails: RouteDetailsType) =>
  fireGtmEvent({
    event: GTM_EVENTS.ROUTE_PLANNER_OPEN_ROUTE_DETAILS,
    ...routeDetails,
  })

export const openMapLocationDetailsPanel = (locationDetails: LocationDetailsType) =>
  fireGtmEvent({
    event: GTM_EVENTS.MAP_LOCATION_DETAILS_OPEN,
    ...locationDetails,
  })

type LocationDetailsCloseType = LocationDetailsType & { elapsedTime: number; hasPhotos: boolean }
export const closeMapLocationDetailsPanel = (locationDetails: LocationDetailsCloseType) =>
  fireGtmEvent({
    event: GTM_EVENTS.MAP_LOCATION_DETAILS_CLOSE,
    ...locationDetails,
  })

export const shareCharger = (chargerDetails: { locationID: number; operator: string }) =>
  fireGtmEvent({
    event: GTM_EVENTS.SHARE_CHARGER,
    ...chargerDetails,
  })

export const feedbackMoodLevel = ({ moodLevel }: { moodLevel: number }) =>
  fireGtmEvent({
    event: GTM_EVENTS.FEEDBACK_MOOD_LEVEL,
    moodLevel,
  })

// prettier-ignore
type ToggleQuickFilterType = { filter: 'Electroverse Compatible' | 'Rapid' | 'Ultra', state: boolean }
export const toggleQuickFilter = (quickFilter: ToggleQuickFilterType) =>
  fireGtmEvent({
    event: GTM_EVENTS.TOGGLE_QUICK_FILTER,
    ...quickFilter,
  })

// prettier-ignore
type ShareURLType = { type: 'filters', platform: 'Facebook' | 'TwitterX' | 'LinkedIn' | 'Copy Link' }
export const shareURL = (shareUrl: ShareURLType) =>
  fireGtmEvent({
    event: GTM_EVENTS.SHARE_URL,
    ...shareUrl,
  })
